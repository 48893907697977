import axios from "axios";
import handleApiError from "@/services/handleApiError";

export default {
    getPromotionDropdowns() {
        return axios.get('/api/promotion/dropdowns').then(resp => resp.data.data.user_status).catch(handleApiError);
    },
    getJobTypeDropdowns() {
        return axios.get('/api/promotion/dropdowns').then(resp => resp.data.data.job_type_id).catch(handleApiError);
    },
    uploadProjectForm(form, id) {
        return axios.post('/api/promotion/create?type=' + id, form).then(resp => resp.data).catch(handleApiError);
    },
    getEventsDropdown(day, month, year) {
        return axios.get('/api/event/list', {params: {day: day, month: month, year: year}}).then(resp => resp.data.data).catch(handleApiError);
    },
    getAllDropdowns() {
        return axios.get('/api/promotion/dropdowns').then(resp => resp.data.data).catch(handleApiError);
    },
    getUserProject(id) {
        return axios.get('/api/promotion/view?', {params: {id: id}}).then(resp => resp.data.data).catch(handleApiError);
    },
    uploadEditProject(form, id) {
        return axios.post('/api/promotion/update?', form, { params: { id: id } }).then(resp => resp.data.data).catch(handleApiError);
    },
    getUserId() {
        return axios.get('/api/user').then(resp => resp.data.user.id);
    },
    getPromotionList(id, type) {
        return axios.get('/api/promotion?', { params: { user_id: id, type: type } }).then(resp => resp.data.data);
    },
    getRegionList(){
        return axios.get('/api/region?typeIds=1&limit=28').then(resp => resp.data.results).catch(handleApiError);
    },
    getCitiesListByRegiom(parentId){
        return axios.get('/api/region?', { params: { typeIds: '3', limit: -1, parentId: parentId} }).then(resp => resp.data.results).catch(handleApiError);
    },
    async getUsersDropdown(last_name){
        const users = await axios.get('/api/user/search?', { params: { is_deputy: 0, limit: 20, last_name: last_name} }).then(resp => resp.data.data.items).catch(handleApiError);
        let result = [];
        for (let i=0; i<users.length; i++){
            result.push({code:users[i].id, name:users[i].last_name + ' '+ users[i].first_name + ' ' + users[i].middle_name});
            // result[users[i].id] = users[i].last_name + ' '+ users[i].first_name + ' ' + users[i].middle_name;
        }
        return result;
    },
    async getUsersByIds(ids){
        let result = [];
        for (let id in ids){
            const user = await axios.get('/api/user/view', { params: { notDeleted: 0, active: 0, id: ids[id]} }).then(resp => resp.data.data).catch(handleApiError);
            if(user){
                result.push({code:user.id, name:user.last_name + ' '+ user.first_name + ' ' + user.middle_name})
            }
        }
        return result
    }

}
