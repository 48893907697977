<template>
    <div class="container-user-project">
        <div class="user-project">
            <div class="user-project__title">
                Я — реалізую партійні проєкти
            </div>
            <div class="user-project__inputs">
                <div class="user-project__left">
                    <div class="user-project__project-field user-project__project-name">
                        <span class="input-label">Назва проєкту</span>
                        <p class="value">{{title}}</p>
                    </div>
                    <div class="user-project__project-field user-project__user-status">
                        <span class="select-label">На яку проблему спрямований проект?</span>
                        <p class="value">{{userStatusStr}}</p>
                    </div>
                    <div class="user-project__project-field user-project__place" v-if="statusValue==='8'">
                        <span class="input-label">Вкажіть проблему проекту.</span>
                        <p class="value">{{location}}</p>
                    </div>
                    <div class="user-project__project-field user-project__file">
                        <span class="input-label">Головна ідея</span>
                        <p class="value" v-if="additional_files"><a :href="additional_files.url" target="_blank">{{ additional_files.name }}</a></p>
                    </div>
                    <div class="user-project__project-field user-project__user-init">
                        <span class="select-label">Ініціатори</span>
                        <p class="value" v-for="user in userIdsValue">{{user.name}}</p>
                    </div>
                </div>
                <div class="user-project__right">
                    <div class="user-project__project-field user-project__user-status">
                        <span class="select-label">Цільова аудиторія</span>
                        <p class="value">{{jobsTypeValue}}</p>
                    </div>
                    <div class="user-project__inputs">
                        <div class="user-project__left">
                            <div class="user-project__project-field user-project__user-region">
                                <span class="select-label">Область</span>
                                <p class="value">{{regionValue}}</p>
                            </div>
                        </div>
                        <div class="user-project__right">
                            <div class="user-project__project-field user-project__user-region">
                                <span class="select-label">Місто</span>
                                <p class="value">{{cityValue}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="user-project__project-field user-project__file">
                        <span class="input-label">Бюджет</span>
                        <p class="value" v-if="file"><a :href="file.url" target="_blank">{{ file.name }}</a></p>
                    </div>
                    <div class="user-project__project-field user-project__description">
                        <span class="input-label">Що інноваційного?</span>
                        <p class="value">{{description}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PromotionService from "@/services/PromotionService";


export default {
    name: "user-projects-view",
    components: {},
    data() {
        return {
            title:'',
            userStatusStr:'',
            statusValue: '',
            location: '',
            additional_files: '',
            file: '',
            userIdsValue: '',
            jobsTypeValue: '',
            regionValue: '',
            cityValue: '',
            description: '',
        }
    },
    methods: {
        async getProject() {
                let resp = await PromotionService.getUserProject(+this.$route.params.id).catch(this.handleError)
                this.title = resp.title
                this.userStatusStr = resp.userStatusStr
                this.statusValue = String(resp.user_status)
                this.location = resp.location
                this.additional_files = resp.additionalFiles[0]
                this.file = resp.files[0]
                this.jobsTypeValue = String(resp.jobType.name)
                this.regionValue = String(resp.region.name)
                if (this.regionValue) {
                    this.cityValue = String(resp.area.name)
                }
                this.description = resp.description
            if (resp.user_ids) {
                this.userIdsValue = await PromotionService.getUsersByIds(resp.user_ids).catch(this.handleError)
            }
        },
    },
    created() {
        this.getProject()
    },
}
</script>

<style scoped lang="less">
.container-user-project {
    background-color: #f5f5f5;
    width: 100%;
    min-height: 100vh;
    float: right;
    display: flex;
    justify-content: center;
    align-items: baseline;

    @media (max-width: 992px) {
        width: 100%;
    }

    .user-project {
        margin: 36px 20px;
        padding: 0 40px;
        width: 1200px;
        min-height: 525px;
        border-radius: 10px;
        border: solid 1px #cdd4e0;
        background-color: #fff;
        @media (max-width: 992px) {
            margin: 36px 0;
        }

        &__title {
            font-family: @secondFontFamily;
            margin-top: 50px;
            font-weight: 500;
            font-size: 24px;
            color: #1a2744;
            display: flex;
            justify-content: center;
        }

        &__inputs {
            margin-top: 50px;
            display: flex;
            justify-content: space-between;

            @media (max-width: 768px) {
                flex-direction: column;
            }

            .user-project__left {
                padding-right: 40px;

                @media (max-width: 768px) {
                    padding-right: 0;
                }
            }
            .user-project__right{
                bottom: 6px;
            }
            .user-project__left, .user-project__right {
                width: 50%;
                position: relative;

                @media (max-width: 768px) {
                    width: 100%;
                }

                .input-label, .select-label {
                    font-size: 12px;
                    color: #1a2744;
                    position: relative;
                    left: 20px;
                    bottom: 5px;

                    &:before {
                        content: '*';
                        color: #ff0048;
                        position: relative;
                        right: 4px;
                    }
                }

                .value{
                    padding: 5px 5px 5px 20px;
                    word-break: break-word;
                }

                .select-label {
                    bottom: 0;
                }

                .user-project__project-field {
                    margin-top: 20px;
                    position: relative;
                }

                .user-project__project-name, .user-project__place, .user-project__description {
                    .input-project-name {
                        display: block;
                        outline: none;
                        width: 100%;
                        height: 50px;
                        border: 1px solid #cdd4e0;
                        border-radius: 10px;
                        font-size: 16px;
                        font-weight: 500;
                        padding-left: 20px;
                    }
                }

                .user-project__description, .user-project__file {
                    .input-label:before {
                        content: '';
                    }
                }
            }

            .user-project__right {
                .user-project__inputs {
                    margin-top: 0;
                }
            }

            .user-project__file {
                margin-top: 20px;

                .label-for-file {
                    border-radius: 10px;
                    width: 100%;
                    height: 50px;
                    border: solid 1px #cdd4e0;
                    cursor: pointer;
                    position: relative;
                    display: flex;
                    align-items: center;
                    padding-left: 20px;

                    span {
                        max-width: 85%;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        -webkit-line-clamp: 2;
                        display: block;
                    }

                    &:after {
                        content: url('../assets/images/attach-paperclip-symbol.png');
                        cursor: pointer;
                        position: absolute;
                        right: 20px;
                        top: 15px;
                    }
                }
            }
        }

        &__btn {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 40px;
            margin-bottom: 40px;

            @media (max-width: 768px) {
                margin-bottom: 40px;
            }
        }
    }
}

.user-project__user-init{
    @media (max-width: 992px){
        margin-bottom: 40px;
    }
}

</style>
